<template>
  <div>
    <div
      v-if="post"
      class="banner"
      :style="{backgroundColor: post.card.colour}"
    >
      <div class="banner-inner">
        <div class="banner-content grid-x align-middle">
          <div class="cell medium-offset-1" :class="{'small-6 medium-5': !post.hideBannerImage, 'small-10 medium-7': post.hideBannerImage}">
            <p class="banner-category">{{ post.category.name }}</p>
            <p class="banner-title">{{ post.card.title }}</p>
          </div>
        </div>
        <div
          class="banner-image"
          v-if="!post.hideBannerImage"
          :style="{backgroundImage: `url(${post.card.image.url})`}"
        />
      </div>
    </div>
    <div class="grid-x content" v-if="post">
      <div class="cell medium-4 medium-offset-1">
        <h1 class="title" :style="{color: post.card.colour}">{{ post.title }}</h1>
        <div class="text" v-html="post.content"/>
      </div>
      <div class="cell medium-6 medium-offset-1">
        <v-image class="image" v-if="post.image" :image="post.image"/>
      </div>
    </div>
    <div class="grid-x" v-if="post && post.navigation">
      <div
        v-for="post in post.navigation.more"
        :key="post.id"
        class="cell medium-4"
      >
        <whats-on-card :post="post"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import VImage from '@/components/Image';
  import WhatsOnCard from '@/components/WhatsOnCard';

  export default {
    props: {
      slug: String,
      categorySlug: String
    },
    components: {
      VImage,
      WhatsOnCard
    },
    mounted() {
      this.grabPost();
    },
    methods: {
      ...mapActions(['fetchPost', 'fetchPostNavigation']),
      grabPost() {
        this.fetchPost(this.slug)
          .then(() => {
            this.fetchPostNavigation(this.slug)
          });
      }
    },
    computed: {
      post() {
        return this.$store.getters.getPost(this.slug);
      }
    },
    watch: {
      slug() {
        this.grabPost();
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import 'foundation-sites/scss/foundation.scss';
  @import '@/assets/scss/_settings';
  .banner {
    color: #fff;
    text-transform: uppercase;
    @include breakpoint(small only) {
      padding-top: vw-relative(60, 320);
    }
  }
  .banner-inner {
    padding-top: 40%;
    position: relative;
  }
  .banner-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    @include breakpoint(small only) {
      padding: {
        left: vw-relative(16, 320);
        right: vw-relative(16, 320);
      }
    }
  }
  .banner-category {
    font: bold #{vw-relative(12, 320)}/1 CenturyGothicPaneuropean;
    margin-bottom: .5em;
    @include breakpoint(medium) {
      margin-bottom: 2em;
      font-size: vw-relative(20);
    }
  }
  .banner-title {
    font: 300 #{vw-relative(16, 320)}/1.1 CenturyGothicPaneuropean;
    @include breakpoint(small only) {
      margin-bottom: vw-relative(20, 320);
    }
    @include breakpoint(medium) {
      font-size: vw-relative(60);
    }
  }
  .banner-image {
    width: calc(100% - #{vw-relative(16, 320)});
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right center;
    @include breakpoint(medium) {
      width: calc((100% / 12) * 11);
    }
  }
  .content {
    padding: vw-relative(16, 320);
    @include breakpoint(medium) {
      padding: vw-relative(90) 0;
    }
  }
  .title {
    font: 300 #{vw-relative(16, 320)}/1.1 CenturyGothicPaneuropean;
    text-transform: uppercase;
    margin-bottom: .6em;
    @include breakpoint(medium) {
      font-size: vw-relative(36);
    }
  }
  .text {
    font: 300 #{vw-relative(16, 320)}/1.2 CenturyGothicPaneuropean;
    @include breakpoint(medium) {
      font-size: vw-relative(18);
    }
    &::v-deep {
      & p {
        font: inherit;
        margin-bottom: 1.2em;
      }
      & ul {
        list-style-position: inside;
        margin-bottom: 1.2em;
      }
    }
  }
  .image {
    width: 100%;
    height: auto;
  }
</style>
